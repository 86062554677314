import { createIcon } from '@chakra-ui/react'

export const SwapBoldIcon = createIcon({
  displayName: 'Swap',
  path: (
    <g>
      <path
        d='M17.8721 4.17442C17.8721 3.52458 18.3967 3 19.0466 3C19.6964 3 20.221 3.52458 20.221 4.17442V8.95824C20.22 8.96421 20.219 8.97005 20.218 8.9758C20.2113 9.01529 20.2053 9.05033 20.2053 9.09134L20.1818 9.1853C20.1753 9.20491 20.1701 9.22315 20.1652 9.2406C20.1583 9.26496 20.1518 9.28776 20.1427 9.31057L20.0957 9.40452C20.0864 9.42005 20.0783 9.43559 20.0705 9.45063C20.0586 9.4735 20.0472 9.49524 20.0331 9.51414C20.0223 9.53576 20.0077 9.55365 19.992 9.57296C19.985 9.58162 19.9777 9.59057 19.9704 9.60026L19.9235 9.67073C19.9156 9.67856 19.9078 9.67856 19.9078 9.67856C19.8138 9.78034 19.6964 9.86646 19.5711 9.9291C19.5633 9.9291 19.5574 9.93301 19.5516 9.93693C19.5457 9.94084 19.5398 9.94476 19.532 9.94476C19.4067 10.0074 19.2736 10.0387 19.1327 10.0465H14.3489C13.699 10.0465 13.1744 9.52197 13.1744 8.87212C13.1744 8.22227 13.699 7.69769 14.3489 7.69769H15.9852L15.9148 7.63506C15.0692 6.8756 14.0279 6.38234 12.9082 6.21009C11.7808 6.03001 10.6377 6.1866 9.60419 6.64854C8.5707 7.11048 7.6938 7.85428 7.07527 8.80948C6.45674 9.76468 6.1279 10.8686 6.1279 12.0039C6.1279 12.3171 6.00263 12.6146 5.7834 12.8338C5.56418 13.0531 5.26666 13.1783 4.95348 13.1783C4.30363 13.1783 3.77905 12.6538 3.77905 12.0039C3.77905 10.4145 4.24099 8.86429 5.11007 7.53328C5.97131 6.20226 7.20054 5.15311 8.649 4.50326C10.0975 3.85341 11.6947 3.64202 13.2684 3.88473C14.8421 4.12745 16.2984 4.82427 17.4807 5.88125L17.8721 6.23358V4.17442Z'
        fill='currentColor'
      />
      <path
        d='M17.8721 12.0039C17.8721 11.3554 18.3946 10.8316 19.0426 10.8295C19.3544 10.8305 19.6503 10.9557 19.8687 11.174C20.0879 11.3932 20.2132 11.6907 20.2132 12.0039C20.2132 13.5933 19.759 15.1435 18.89 16.4746C18.0287 17.8056 16.7995 18.8547 15.351 19.5046C14.2862 19.9743 13.151 20.2171 12 20.2171C11.5772 20.2171 11.1544 20.1779 10.7316 20.1153C9.15791 19.8726 7.70163 19.1757 6.51937 18.1187L6.1279 17.7664V19.8256C6.1279 20.4754 5.60332 21 4.95348 21C4.30363 21 3.77905 20.4754 3.77905 19.8256V15.1279C3.77905 15.0026 3.80254 14.8852 3.84169 14.7756C3.84952 14.7599 3.85735 14.7442 3.85735 14.7286C3.90432 14.6033 3.98262 14.478 4.06874 14.3763C4.07173 14.3703 4.07473 14.3654 4.07728 14.3613C4.08141 14.3546 4.0844 14.3498 4.0844 14.3449C4.0844 14.3293 4.10006 14.3293 4.10006 14.3293C4.19402 14.2275 4.31146 14.1414 4.43673 14.0787C4.44456 14.0709 4.46022 14.0631 4.46805 14.0631C4.59332 14.0083 4.72642 13.9691 4.86735 13.9613H9.659C10.3088 13.9613 10.8334 14.4859 10.8334 15.1357C10.8334 15.7856 10.3088 16.3101 9.659 16.3101H8.02264L8.0931 16.3728C8.93869 17.1322 9.98001 17.6255 11.0996 17.7977C12.2192 17.9778 13.3624 17.8212 14.3958 17.3593C15.4293 16.8973 16.3062 16.1535 16.9248 15.1983C17.5433 14.2431 17.8721 13.1392 17.8721 12.0039Z'
        fill='currentColor'
      />
      <path d='M19.0426 10.8295L19.0466 10.8295H19.0387L19.0426 10.8295Z' fill='currentColor' />
    </g>
  ),
  viewBox: '0 0 24 24',
})
