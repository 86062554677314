import { createIcon } from '@chakra-ui/react'

export const PoolsIcon = createIcon({
  displayName: 'Pools',
  path: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 19.9918C12.6836 19.9918 13.3472 19.906 13.9805 19.7445C11.7385 17.9107 10.3073 15.1224 10.3073 12C10.3073 8.87754 11.7384 6.08933 13.9805 4.2555C13.3472 4.09403 12.6836 4.00819 12 4.00819C7.58625 4.00819 4.00819 7.58625 4.00819 12C4.00819 16.4138 7.58625 19.9918 12 19.9918ZM16.0726 18.7792C16.0995 18.796 16.1264 18.8126 16.1534 18.8291C17.0788 18.2651 17.8805 17.5183 18.5085 16.6388H13.7985C14.4075 17.4918 15.1806 18.2205 16.0726 18.7792ZM19.5487 14.6306H12.7583C12.5773 14.1108 12.4484 13.5666 12.378 13.0042H19.9293C19.8588 13.5666 19.7299 14.1109 19.5487 14.6306ZM19.9294 10.9961H12.3779C12.4484 10.4337 12.5772 9.88936 12.7583 9.36942H19.5488C19.7299 9.88927 19.8589 10.4336 19.9294 10.9961ZM18.5085 7.36123H13.7984C14.4075 6.50824 15.1805 5.77953 16.0726 5.22079C16.0994 5.204 16.1264 5.18736 16.1534 5.17088C17.0788 5.73491 17.8806 6.48175 18.5085 7.36123Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})
