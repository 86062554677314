import { createIcon } from '@chakra-ui/react'

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  path: (
    <path
      d='M15.135 2.09545C15.635 1.67326 16.3627 1.67326 16.8627 2.09545L31.528 14.5388C32.0891 15.0165 32.1613 15.8553 31.6836 16.4164C31.2058 16.9774 30.367 17.0441 29.8059 16.5719L28.4394 15.4165V25.7766C28.4394 28.232 26.4507 30.2207 23.9954 30.2207H7.99681C5.54147 30.2207 3.55276 28.232 3.55276 25.7766V15.4165L2.19177 16.5719C1.63071 17.0496 0.786347 16.9774 0.314167 16.4164C-0.158012 15.8553 -0.0913517 15.0109 0.469709 14.5388L15.135 2.09545ZM15.9961 4.86186L6.21919 13.1556V25.7766C6.21919 26.7599 7.01356 27.5543 7.99681 27.5543H10.6632V19.1106C10.6632 17.8829 11.6576 16.8886 12.8853 16.8886H19.1069C20.3346 16.8886 21.3289 17.8829 21.3289 19.1106V27.5543H23.9954C24.9786 27.5543 25.773 26.7599 25.773 25.7766V13.1556L15.9961 4.86186ZM13.3297 27.5543H18.6625V19.555H13.3297V27.5543Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 32 32',
})
