import { createIcon } from '@chakra-ui/react'
/**
 * Keplr Icon
 *
 * @see https://github.com/chainapsis/keplr-wallet/blob/master/packages/extension/src/public/assets/temp-icon.svg
 */
export const KeplrIcon = createIcon({
  displayName: 'KeplrIcon',
  path: (
    <svg>
      <defs>
        <radialGradient
          cx='90.8672534%'
          cy='10.9586258%'
          fx='90.8672534%'
          fy='10.9586258%'
          r='172.001111%'
          gradientTransform='translate(0.908673,0.109586),rotate(140.172042),scale(1.000000,1.206725),translate(-0.908673,-0.109586)'
          id='radialGradient-1'
        >
          <stop stopColor='#2F80F2' offset='0%'></stop>
          <stop stopColor='#A942B5' offset='99.9656883%'></stop>
        </radialGradient>
        <radialGradient
          cx='0%'
          cy='2.14812083%'
          fx='0%'
          fy='2.14812083%'
          r='159.107686%'
          gradientTransform='translate(0.000000,0.021481),rotate(46.320810),scale(1.000000,1.047856),translate(-0.000000,-0.021481)'
          id='radialGradient-2'
        >
          <stop stopColor='#45F9DE' offset='0%'></stop>
          <stop stopColor='#A942B5' stopOpacity='0' offset='100%'></stop>
        </radialGradient>
        <radialGradient
          cx='100%'
          cy='100%'
          fx='100%'
          fy='100%'
          r='96.0436813%'
          gradientTransform='translate(1.000000,1.000000),rotate(180.000000),scale(1.000000,0.514961),translate(-1.000000,-1.000000)'
          id='radialGradient-3'
        >
          <stop stopColor='#E957C5' offset='0%'></stop>
          <stop stopColor='#AC43B6' stopOpacity='0.0437004098' offset='100%'></stop>
          <stop stopColor='#A942B5' stopOpacity='0' offset='100%'></stop>
        </radialGradient>
        <radialGradient
          cx='50%'
          cy='50%'
          fx='50%'
          fy='50%'
          r='57.6518464%'
          gradientTransform='translate(0.500000,0.500000),rotate(119.937813),scale(1.000000,1.499193),translate(-0.500000,-0.500000)'
          id='radialGradient-4'
        >
          <stop stopColor='#000000' stopOpacity='0.184877622' offset='0%'></stop>
          <stop stopColor='#101010' offset='100%'></stop>
        </radialGradient>
        <linearGradient x1='94.2375438%' y1='58.966132%' x2='0%' y2='0%' id='linearGradient-5'>
          <stop stopColor='#FFFFFF' stopOpacity='0.184877622' offset='0%'></stop>
          <stop stopColor='#FFFFFF' offset='100%'></stop>
        </linearGradient>
      </defs>
      <g id='Artboard-Copy-6' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group'>
          <rect
            id='Rectangle'
            fill='url(#radialGradient-1)'
            x='0'
            y='0'
            width='200'
            height='200'
            rx='16'
          ></rect>
          <rect
            id='Rectangle-Copy-2'
            fillOpacity='0.57'
            fill='url(#radialGradient-2)'
            x='0'
            y='0'
            width='200'
            height='200'
            rx='16'
          ></rect>
          <rect
            id='Rectangle-Copy-3'
            fillOpacity='0.68'
            fill='url(#radialGradient-3)'
            x='0'
            y='0'
            width='200'
            height='200'
            rx='16'
          ></rect>
          <rect
            id='Rectangle-Copy'
            fillOpacity='0.08'
            fill='url(#radialGradient-4)'
            x='0'
            y='0'
            width='200'
            height='200'
            rx='16'
          ></rect>
          <rect
            id='Rectangle-Copy-4'
            fillOpacity='0.03'
            fill='url(#linearGradient-5)'
            x='0'
            y='0'
            width='200'
            height='200'
            rx='16'
          ></rect>
        </g>
        <polygon
          id='K'
          fill='#FFFFFF'
          fillRule='nonzero'
          points='70.4 170 70.4 108 129 170 161.6 170 161.6 168.4 94.2 97.8 156.4 30.8 156.4 30 123.6 30 70.4 89.2 70.4 30 44 30 44 170'
        ></polygon>
      </g>
    </svg>
  ),
  viewBox: '0 0 200 200',
})
