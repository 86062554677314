import { createIcon } from '@chakra-ui/react'

export const AssetsIcon = createIcon({
  displayName: 'Assets',
  path: (
    <g>
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.21983 3.19027C4.53621 4.03193 3.84615 5.02412 3.84615 5.84615C3.84615 6.66818 4.53621 7.66037 6.21983 8.50204C7.84832 9.31614 10.1626 9.84615 12.7692 9.84615C15.3758 9.84615 17.6901 9.31614 19.3186 8.50204C21.0022 7.66037 21.6923 6.66818 21.6923 5.84615C21.6923 5.02412 21.0022 4.03193 19.3186 3.19027C17.6901 2.37616 15.3758 1.84615 12.7692 1.84615C10.1626 1.84615 7.84832 2.37616 6.21983 3.19027ZM23.5385 5.84615C23.5385 3.94818 22.0242 2.47884 20.1441 1.53896C18.2089 0.571528 15.6002 0 12.7692 0C9.93829 0 7.32952 0.571528 5.39432 1.53896C3.51425 2.47884 2 3.94818 2 5.84615V18.1538C2 20.0518 3.51425 21.5212 5.39432 22.461C7.32952 23.4285 9.93829 24 12.7692 24C15.6002 24 18.2089 23.4285 20.1441 22.461C22.0242 21.5212 23.5385 20.0518 23.5385 18.1538V5.84615ZM21.6923 9.17851C21.2286 9.5484 20.7025 9.87422 20.1441 10.1533C18.2089 11.1208 15.6002 11.6923 12.7692 11.6923C9.93829 11.6923 7.32952 11.1208 5.39432 10.1533C4.83597 9.87422 4.30989 9.5484 3.84615 9.17851V12C3.84615 12.822 4.53621 13.8142 6.21983 14.6559C7.84832 15.47 10.1626 16 12.7692 16C15.3758 16 17.6901 15.47 19.3186 14.6559C21.0022 13.8142 21.6923 12.822 21.6923 12V9.17851ZM21.6923 15.3324C21.2286 15.7022 20.7025 16.0281 20.1441 16.3072C18.2089 17.2746 15.6002 17.8462 12.7692 17.8462C9.93829 17.8462 7.32952 17.2746 5.39432 16.3072C4.83597 16.0281 4.30989 15.7022 3.84615 15.3324V18.1538C3.84615 18.9759 4.53621 19.9681 6.21983 20.8097C7.84832 21.6238 10.1626 22.1538 12.7692 22.1538C15.3758 22.1538 17.6901 21.6238 19.3186 20.8097C21.0022 19.9681 21.6923 18.9759 21.6923 18.1538V15.3324Z'
      />
    </g>
  ),
  viewBox: '0 0 24 24',
})
