import { createIcon } from '@chakra-ui/react'

export const SliderIcon = createIcon({
  displayName: 'Slider',
  path: (
    <path
      d='M7.437 3.5c.241 0 .438-.197.438-.438v-.437h5.687c.241 0 .438-.197.438-.438v-.875a.439.439 0 0 0-.438-.437H7.875V.437A.439.439 0 0 0 7.437 0h-.875a.439.439 0 0 0-.437.437v.438H.437A.439.439 0 0 0 0 1.312v.875c0 .241.197.438.437.438h5.688v.437c0 .241.197.438.437.438h.875Zm3.5 4.375c.241 0 .438-.197.438-.438V7h2.187c.241 0 .438-.197.438-.438v-.875a.439.439 0 0 0-.438-.437h-2.187v-.438a.439.439 0 0 0-.438-.437h-.875a.439.439 0 0 0-.437.437v.438H.437A.439.439 0 0 0 0 5.687v.875C0 6.803.197 7 .437 7h9.188v.437c0 .241.197.438.437.438h.875Zm-7 4.375c.241 0 .438-.197.438-.438v-.437h9.187c.241 0 .438-.197.438-.438v-.875a.439.439 0 0 0-.438-.437H4.375v-.438a.439.439 0 0 0-.438-.437h-.875a.439.439 0 0 0-.437.437v.438H.437a.439.439 0 0 0-.437.437v.875c0 .241.197.438.437.438h2.188v.437c0 .241.197.438.437.438h.875Z'
      fill='currentColor'
      fillRule='nonzero'
    />
  ),
  viewBox: '0 0 14 13',
})
