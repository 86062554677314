import { createIcon } from '@chakra-ui/react'

export const WalletIcon = createIcon({
  displayName: 'AccountsIcon',
  path: (
    <path
      d='M4.125 1.5C1.84688 1.5 0 3.34688 0 5.625V18.375C0 20.6531 1.84688 22.5 4.125 22.5H19.875C22.1531 22.5 24 20.6531 24 18.375V10.125C24 7.84688 22.1531 6 19.875 6H5.625C5.00156 6 4.5 6.50156 4.5 7.125C4.5 7.74844 5.00156 8.25 5.625 8.25H19.875C20.9109 8.25 21.75 9.08906 21.75 10.125V18.375C21.75 19.4109 20.9109 20.25 19.875 20.25H4.125C3.08906 20.25 2.25 19.4109 2.25 18.375V5.625C2.25 4.58906 3.08906 3.75 4.125 3.75H21.375C21.9984 3.75 22.5 3.24844 22.5 2.625C22.5 2.00156 21.9984 1.5 21.375 1.5H4.125ZM18 15.75C18.3978 15.75 18.7794 15.592 19.0607 15.3107C19.342 15.0294 19.5 14.6478 19.5 14.25C19.5 13.8522 19.342 13.4706 19.0607 13.1893C18.7794 12.908 18.3978 12.75 18 12.75C17.6022 12.75 17.2206 12.908 16.9393 13.1893C16.658 13.4706 16.5 13.8522 16.5 14.25C16.5 14.6478 16.658 15.0294 16.9393 15.3107C17.2206 15.592 17.6022 15.75 18 15.75Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})
