import { createIcon } from '@chakra-ui/react'

export const BadgeCheckIcon = createIcon({
  displayName: 'BadgeCheck',
  path: (
    <path
      d='M20.9013 10.9919C21.0718 10.7845 21.0146 10.4703 20.7821 10.3387L18.251 8.90741C18.1149 8.83052 18.0314 8.68437 18.0329 8.52615L18.0639 5.50054C18.0667 5.22635 17.8201 5.0192 17.5557 5.07322L14.6223 5.67147C14.4667 5.70325 14.3068 5.64415 14.2073 5.51865L12.3435 3.1612C12.1742 2.94706 11.8531 2.94611 11.6825 3.1593L9.77208 5.5482C9.67225 5.67306 9.51203 5.7312 9.3565 5.6988L6.54572 5.11007C6.27785 5.05383 6.02844 5.26575 6.03532 5.54343L6.11011 8.51281C6.11418 8.67802 6.02531 8.83084 5.88073 8.90677L3.22955 10.3006C2.98077 10.4315 2.92256 10.7667 3.11251 10.9757L5.11655 13.1842C5.22858 13.3075 5.25987 13.4854 5.19697 13.6404L4.03223 16.5132C3.92896 16.768 4.08449 17.0546 4.35173 17.1022L7.34308 17.6332C7.49078 17.6592 7.61377 17.7628 7.66696 17.9048L8.71999 20.7217C8.81387 20.973 9.10866 21.0766 9.33553 20.9383L11.8015 19.4365C11.9345 19.3555 12.1 19.3545 12.2339 19.434L14.775 20.9393C15.0062 21.0762 15.3029 20.9647 15.3905 20.708L16.3428 17.9153C16.3931 17.7672 16.519 17.6586 16.6707 17.6316L19.6483 17.1019C19.9146 17.0546 20.0701 16.7699 19.9687 16.5154L18.886 13.7974C18.8272 13.65 18.8531 13.4816 18.9536 13.3592L20.901 10.9916L20.9013 10.9919ZM10.7444 15.2716L7.99523 12.4804L9.17907 11.2785L10.7444 12.8677L14.821 8.72885L16.0048 9.93077L10.7441 15.2719L10.7444 15.2716Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})
