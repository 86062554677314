import { createIcon } from '@chakra-ui/react'

export const ErrorIcon = createIcon({
  displayName: 'Error',
  path: (
    <path
      d='M11.6045 4.03628C11.922 3.97581 12.2395 3.99093 12.5569 4.08163C13.2674 4.26304 13.7512 4.69388 14.0082 5.37415C14.0686 5.60091 14.0535 6.06954 13.9628 6.78005C13.9024 7.17309 13.7285 8.29932 13.4413 10.1587C13.018 12.9101 12.7534 14.3311 12.6476 14.4218C12.5418 14.4671 12.179 14.4747 11.5592 14.4444C11.4383 14.4444 11.3702 14.4369 11.3551 14.4218C11.2795 14.3764 11.2115 14.2026 11.151 13.9002C11.1057 13.6281 10.9092 12.381 10.5615 10.1587C10.1231 7.28647 9.91898 5.75208 9.94921 5.55556C9.94921 5.52532 9.96433 5.46485 9.99457 5.37415C10.1911 4.73923 10.6522 4.31595 11.3778 4.10431C11.4534 4.07407 11.529 4.0514 11.6045 4.03628ZM11.6272 15.9184C11.8842 15.8579 12.179 15.873 12.5116 15.9637C13.1767 16.13 13.6378 16.5382 13.8948 17.1882C13.9401 17.2638 13.9704 17.3469 13.9855 17.4376C14.0762 17.7853 14.0762 18.1255 13.9855 18.4581C13.8041 19.1534 13.3808 19.6221 12.7157 19.8639C12.6401 19.8942 12.572 19.9169 12.5116 19.932C12.1639 20.0227 11.8237 20.0227 11.4912 19.932C10.7958 19.7506 10.3271 19.3273 10.0853 18.6621C10.055 18.5865 10.0324 18.5185 10.0172 18.4581C9.92654 18.1255 9.92654 17.7853 10.0172 17.4376C10.1382 16.9841 10.3649 16.6289 10.6975 16.3719C10.9545 16.1602 11.2644 16.0091 11.6272 15.9184Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 24 24',
})
